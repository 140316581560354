// FIXME : for whitelabeling some color at scheme below will come from .env depends on primary or secondary color

import { ColorBlue, ColorDark, ColorLight } from '@uangcermat/uikit-web';
import {
  CheckPickerColorSchemeInterface,
  ColorSchemeInterface,
  SelectPickerColorSchemeInterface,
  SidebarColorSchemeInterface,
  TableColorSchemeInterface
} from '@uangcermat/uikit-web/build/src/styles/products/productColorScheme.type';

const gajicermatTableColorScheme: TableColorSchemeInterface = {
  pagination: {
    activePageNumberText: ColorDark.blackCoral
  }
};

const gajicermatSidebarColorScheme: SidebarColorSchemeInterface = {
  background: ColorLight.whiteSolid,
  menuItem: {
    parent: {
      text: {
        default: ColorDark.bermudaGray,
        active: ColorLight.whiteSolid
      },
      icon: {
        default: ColorDark.bermudaGray,
        active: ColorLight.whiteSolid
      }
    },
    child: {
      text: {
        default: ColorDark.gumbo,
        active: ColorDark.blackCoral
      }
    }
  },
  collapsableMenu: {
    background: ColorLight.whiteSolid,
    active: process.env.BG_SIDE_BAR_ACTIVE_COLOR ?? ColorBlue.yaleBlue,
    hover: {
      default: ColorDark.solitude,
      active: process.env.BG_SIDE_BAR_HOVER_COLOR ?? ColorBlue.yaleBlue
    },
    icon: {
      default: ColorDark.bermudaGray,
      active: ColorLight.whiteSolid
    }
  },
  subMenuItem: {
    default: ColorLight.whiteSolid,
    active: ColorLight.whiteSmoke,
    hover: {
      background: ColorDark.solitude
    }
  },
  subMenuIndicator: {
    default: process.env.SUB_MENU_INDICATOR_COLOR ?? ColorBlue.cornFlowerBlue
  },
  footer: {
    borderRight: ColorDark.bermudaGray,
    text: {
      color: ColorDark.bermudaGray
    }
  }
};

const gajicermatCheckPickerColorScheme: CheckPickerColorSchemeInterface = {
  selectedList: process.env.BG_SELECTED_CHECK_PICKER_COLOR ?? ColorDark.blackCoral,
  count: process.env.BG_COUNT_CHECK_PICKER_COLOR ?? ColorBlue.buttonBlue,
  hoverColor: process.env.BG_HOVER_CHECK_PICKER_COLOR ?? ColorLight.aliceBlue,
  activeColor: process.env.BG_ACTIVE_CHECK_PICKER_COLOR ?? ColorLight.aliceBlue,
  checkBoxColor: process.env.BG_ACTIVE_CHECK_PICKER_COLOR ?? ColorBlue.buttonBlue
};

const gajicermatSelectPickerColorScheme: SelectPickerColorSchemeInterface = {
  hoverColor: ColorLight.whiteSmoke,
  activeColor: ColorLight.whiteSmoke,
  addButtonColor: ColorLight.whiteSmoke
};

export const GajiCermatColorScheme: ColorSchemeInterface = {
  table: gajicermatTableColorScheme,
  sidebar: gajicermatSidebarColorScheme,
  checkPicker: gajicermatCheckPickerColorScheme,
  selectPicker: gajicermatSelectPickerColorScheme
};
